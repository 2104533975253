import styled from "styled-components";
import { PolymorphicComponent } from "../../util/polymorphic";
import Button from "./Button";
import { BaseButtonProps } from "./types";

const IconButton: PolymorphicComponent<BaseButtonProps, "button"> = styled(Button)<BaseButtonProps>`
    padding: 0;
    width: ${({ scale }) => (scale === "sm" ? "32px" : "48px")};
    background: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
`;

export default IconButton;

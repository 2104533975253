import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props} style={{fill:"none"}}>
      <g clipPath="url(#clip0_741_1375)">
      <mask id="mask0_741_1375" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <circle cx="12" cy="12" r="12" fill="#D9D9D9"/>
      </mask>
      <g mask="url(#mask0_741_1375)">
      <path d="M17.9593 22.0409C23.5046 18.7497 25.3319 11.5862 22.0407 6.04093C18.7494 0.495625 11.586 -1.33165 6.04066 1.95961C0.495357 5.25086 -1.33191 12.4143 1.95934 17.9596C5.25059 23.5049 12.414 25.3322 17.9593 22.0409Z" fill="#8BC34A"/>
      <path d="M0.578842 12.2519C0.578842 5.80376 5.80669 0.575913 12.2548 0.575913C15.4143 0.575913 18.2818 1.83146 20.3836 3.87245C18.2622 1.68455 15.2928 0.325195 12.0041 0.325195C5.55597 0.325195 0.328125 5.55304 0.328125 12.0012C0.328125 15.2899 1.68748 18.2593 3.87538 20.3826C1.83635 18.2789 0.578842 15.4133 0.578842 12.2519Z" fill="#AED581"/>
      <path d="M9.65234 17.7356L15.2367 23.22C20.112 21.8176 23.6788 17.3262 23.6788 12.0004C23.6788 11.8966 23.6729 11.7948 23.671 11.6929L18.3667 6.38867L9.65234 17.7356Z" fill="url(#paint0_linear_741_1375)"/>
      <path d="M10.367 17.9248C9.93806 17.9248 9.51498 17.7368 9.229 17.3744L5.39773 12.5422C4.90021 11.9135 5.00598 11.0007 5.63277 10.5032C6.26153 10.0057 7.17429 10.1115 7.67181 10.7382L11.5031 15.5704C12.0006 16.1992 11.8948 17.112 11.268 17.6095C11.0017 17.821 10.6824 17.9229 10.367 17.9229V17.9248Z" fill="white"/>
      <path d="M10.3692 17.9263C10.0539 17.9263 9.73458 17.8245 9.4682 17.6129C8.83944 17.1154 8.73367 16.2026 9.23315 15.5739L16.3277 6.6264C16.8252 5.99765 17.7379 5.89187 18.3667 6.39135C18.9955 6.88887 19.1012 7.80164 18.6017 8.43039L11.5072 17.3779C11.2213 17.7402 10.7962 17.9283 10.3692 17.9283V17.9263Z" fill="white"/>
      </g>
      </g>
      <defs>
      <linearGradient id="paint0_linear_741_1375" x1="13.1859" y1="11.2385" x2="21.1403" y2="19.1929" gradientUnits="userSpaceOnUse">
      <stop stopColor="#689F38"/>
      <stop offset="1" stopColor="#8BC34A"/>
      </linearGradient>
      <clipPath id="clip0_741_1375">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
    </Svg>
  );
};

export default Icon;

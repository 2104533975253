import { createAction } from '@reduxjs/toolkit'
import { ListBalanceDebris, ListDataUser, ListNftBalance, ListRef, ListTntBalance, ListTntWithApprove, NftInfoProps, NftsByTypeProps, NftsMaxSupplyProps, NftsProps, NftsTypeSupplyProps } from "./type"

export const fetchLoading = createAction<{isLoading:boolean}>('mintnft/fetchStatus')
export const getDataUser = createAction<ListDataUser>('mintnftV2/getDataUser')
export const getNftInfoMint = createAction<NftsProps>('mintnftV2/getNftInfoMint')
export const getBalanceNft = createAction<ListNftBalance>('mintnftV2/getBalanceNft')
export const getNftTypeMaxSupply = createAction<NftsMaxSupplyProps>('mintnftV2/getNftTypeMaxSupply')
export const getNftTypeSupply = createAction<NftsTypeSupplyProps>('mintnftV2/getNftTypeSupply')
export const getRefcode = createAction<ListRef>('mintnftV2/getRefcode')
export const getBalanceNftUser = createAction<NftInfoProps>('mintnftV2/getBalanceNftUser')
export const getDataUserTNT = createAction<ListDataUser>('mintnftV2/getDataUserTNT')
export const getBalanceTnt = createAction<ListTntBalance>('mintnftV2/getBalanceTnt')
export const getBalanceDebris = createAction<ListBalanceDebris>('mintnftV2/getBalanceDebris')
export const getIsApproveForAllTnt = createAction<ListTntWithApprove>('mintnftV2/getIsApproveForAllTnt')
export const getSupplyNftByType = createAction<NftsByTypeProps>('mintnftV2/getSupplyNftByType')

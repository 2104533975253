import { createReducer } from '@reduxjs/toolkit'
import {
    getActiveTab,
    getChartData, getDataHistory,
    getFetchingStatus,
    getGoldPrice,
    getNftIdsToStake,
    getNftIdsToUnStake,
    getNftInfo,
    getPageActive,
    getPoolInfo,
    getPowerToStake, getPowerToUsStake, getSelectedTypeForStake,
    getSelectedTypeNftForUnstake
} from "./actions"
import { ItemChart, ItemHistory, NftInfo, PenddingReward } from './type'


interface global {
    activeIndex:number,
    powerToStake: string,
    powerToUnstake:string,
    selectedTypeForStake:string,
    selectedTypeForUnstake:string,
    nftIdsToStake:number[],
    nftIdsToUnStake:number[],
    poolInfo: PenddingReward[]
    isLoading:boolean,
    listNftInfo: NftInfo[],
    listChart: ItemChart[],
    dataHistory: ItemHistory,
    pagenumbermkl:number,
    tokenPrice: {
        hng:string,
        chi:string
    }
}
export const initialState: global = {
    activeIndex:0,
    powerToStake:"",
    powerToUnstake:"",
    selectedTypeForStake:"all",
    selectedTypeForUnstake:"all",
    nftIdsToStake:[],
    poolInfo: [],
    isLoading:false,
    listNftInfo: [],
    nftIdsToUnStake:[],
    listChart: null,
    dataHistory: null,
    pagenumbermkl:1,
    tokenPrice: {
        hng:"0",
        chi:"0"
    },
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(getActiveTab, (state, action) => {
            state.activeIndex = action.payload.activeIndex
        })
        .addCase(getPowerToStake, (state, action) => {
            state.powerToStake = action.payload.powerToStake
        })
        .addCase(getPowerToUsStake, (state, action) => {
            state.powerToUnstake = action.payload.powerToUnstake
        })
        .addCase(getSelectedTypeForStake, (state, action) => {
            state.selectedTypeForStake = action.payload.selectedTypeForStake
        })
        .addCase(getSelectedTypeNftForUnstake, (state, action) => {
            state.selectedTypeForUnstake = action.payload.selectedTypeForUnstake
        })
        .addCase(getNftIdsToStake, (state, action) => {
            state.nftIdsToStake = action.payload.nftIdsToStake
        })
        .addCase(getPoolInfo, (state, action) => {
            state.poolInfo = action.payload.poolInfo
        })
        .addCase(getFetchingStatus, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(getNftInfo, (state, action) => {
            state.listNftInfo = action.payload.listNftInfo
        })
        .addCase(getNftIdsToUnStake, (state, action) => {
            state.nftIdsToUnStake = action.payload.nftIdsToUnStake
        })
        .addCase(getChartData, (state, action) => {
            state.listChart = action.payload.listChart
        })
        .addCase(getDataHistory, (state, action) => {
            state.dataHistory = action.payload.dataHistory
        })
        .addCase(getPageActive, (state, action) => {
            state.pagenumbermkl = action.payload.pagenumbermkl
        })
        .addCase(getGoldPrice, (state, action) => {
            state.tokenPrice = action.payload.tokenPrice
        })
)
const variants = {
  warning: {
    background: "linear-gradient(89.85deg, #F2A93C -1.69%, #FCC574 49.4%, #F29E25 99.87%, rgba(255, 255, 255, 0) 99.87%)",
    borderColor: "warning",
  },
  danger: {
    background: "#FFB23719",
    borderColor: "warning",
  },
  success: {
    background: "rgba(49, 208, 170, 0.1)",
    borderColor: "success",
  },
  text: {
    background: "#FFB23719",
    borderColor: "primaryBright",
  },
};

export default variants;

import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props} style={{fill:"none"}}>
        <g clipPath="url(#clip0_741_1355)">
        <mask id="mask0_741_1355" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <circle cx="12" cy="12" r="12" fill="#D9D9D9"/>
        </mask>
        <g mask="url(#mask0_741_1355)">
        <path d="M23.0597 16.94C25.7882 10.8319 23.0484 3.66848 16.9403 0.940029C10.8322 -1.78842 3.66875 0.95133 0.940297 7.05944C-1.78815 13.1675 0.951598 20.331 7.0597 23.0594C13.1678 25.7879 20.3313 23.0481 23.0597 16.94Z" fill="#F44336"/>
        <path d="M0.146819 12.2588C0.146819 5.56934 5.57032 0.145842 12.2598 0.145842C15.5374 0.145842 18.5123 1.44838 20.6927 3.56575C18.492 1.29597 15.4115 -0.114258 11.9997 -0.114258C5.31022 -0.114258 -0.113281 5.30924 -0.113281 11.9987C-0.113281 15.4105 1.29695 18.491 3.56673 20.6938C1.45138 18.5114 0.146819 15.5385 0.146819 12.2588Z" fill="#E57373"/>
        <path d="M6.42969 17.8238L12.8225 24.0804C18.9043 23.674 23.7568 18.7788 24.0962 12.6787L17.7095 6.29199L6.42969 17.8238Z" fill="url(#paint0_linear_741_1355)"/>
        <path d="M7.36027 18.1484C6.97418 18.1484 6.59013 18.0021 6.29548 17.7075C5.70619 17.1202 5.70619 16.1651 6.29548 15.5779L15.5778 6.29353C16.1651 5.70424 17.1201 5.70424 17.7074 6.29353C18.2967 6.88079 18.2967 7.83584 17.7074 8.4231L8.42505 17.7054C8.13041 18.0001 7.74635 18.1464 7.36027 18.1464V18.1484Z" fill="white"/>
        <path d="M16.6426 18.1484C16.2565 18.1484 15.8725 18.0021 15.5778 17.7075L6.29548 8.4231C5.70619 7.83584 5.70619 6.88079 6.29548 6.29353C6.88274 5.70424 7.8378 5.70424 8.42505 6.29353L17.7074 15.5759C18.2967 16.1631 18.2967 17.1182 17.7074 17.7054C17.4127 18.0001 17.0287 18.1464 16.6426 18.1464V18.1484Z" fill="white"/>
        </g>
        </g>
        <defs>
        <linearGradient id="paint0_linear_741_1355" x1="10.6177" y1="10.606" x2="20.5869" y2="20.5752" gradientUnits="userSpaceOnUse">
        <stop stopColor="#B71C1C"/>
        <stop offset="1" stopColor="#F44336"/>
        </linearGradient>
        <clipPath id="clip0_741_1355">
        <rect width="24" height="24" fill="white"/>
        </clipPath>
        </defs>
    </Svg>
  );
};

export default Icon;

import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 25 28" fill="none" {...props}>
        <g filter="url(#filter0_d_702_16230)">
        <path d="M18.5552 6.0918L6.18555 18.647" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.18555 6.0918L18.5552 18.647" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <filter id="filter0_d_702_16230" x="1.43555" y="5.3418" width="21.8691" height="22.0547" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.191667 0 0 0 0 0.186076 0 0 0 0 0.186076 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_702_16230"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_702_16230" result="shape"/>
        </filter>
        </defs>
    </Svg>
  );
};

export default Icon;

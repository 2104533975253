import { ChainId, Token, WBNB } from '@pancakeswap/sdk'
import { BUSD_TESTNET, CAKE_TESTNET } from './common'

export const bscTestnetTokens = {
    wbnb: WBNB[ChainId.BSC_TESTNET],
    cake: CAKE_TESTNET,
    busd: BUSD_TESTNET,
    syrup: new Token(
      ChainId.BSC_TESTNET,
      '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
      18,
      'SYRUP',
      'SyrupBar Token',
      'https://pancakeswap.finance/',
    ),
    bake: new Token(
      ChainId.BSC_TESTNET,
      '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      18,
      'BAKE',
      'Bakeryswap Token',
      'https://www.bakeryswap.org/',
    ),
    hbtc: new Token(ChainId.BSC_TESTNET, '0x3Fb6a6C06c7486BD194BB99a078B89B9ECaF4c82', 18, 'HBTC', 'Huobi BTC'),
    wbtc: new Token(ChainId.BSC_TESTNET, '0xfC8bFbe9644e1BC836b8821660593e7de711e564', 8, 'WBTC', 'Wrapped BTC'),
    usdc: new Token(
      ChainId.BSC_TESTNET,
      '0x2fB98DCc527c1403F92c6Bed05a50725d3005636',
      18,
      'USDC',
      'Binance-Peg USD Coin',
    ),
    nft: new Token(
        ChainId.BSC_TESTNET,
        '0x71cCe580af461FAEbe6E5BB883b8F941a11ae364',
        0,
        'HNGNFT',
        'HanaGold NFT',
        '',
    ),
    vndc: new Token(
      ChainId.BSC_TESTNET,
      '0x3f8fcce16aae2c6425609ef5c0cae96cd464e0de',
      18,
      'VNDC',
      'VNDC Token',
      '',
    ),
    hng: new Token(
      ChainId.BSC_TESTNET,
      '0xd985c0afa4b8cb65695aEcAae03504409c701988',
      18,
      'HNG',
      'HANAGOLD Token',
      '',
    ),
    bongmieu: new Token(
      ChainId.BSC_TESTNET,
      '0xf7CF5b9797723601aE07f845518729a43F7ba958',
      0,
      'BM',
      'Mỏ vàng Bông Miêu',
      '',
    ),
    palang: new Token(
      ChainId.BSC_TESTNET,
      '0x992fA733833bFd080F1B191CC17075ca23152d99',
      0,
      'PL',
      'Mỏ vàng Pá Lạng',
      '',
    ),
    bocu: new Token(
      ChainId.BSC_TESTNET,
      '0xa31C1f04BD7e22a1B07635De728B952Fe0A7C853',
      0,
      'BC',
      'Mỏ vàng Bồ Cu',
      '',
    ),
    chi: new Token(
      ChainId.BSC_TESTNET,
      '0xbf2142c8b03808B6a13A811143Bd46d01B8b4C5f',
      18,
      'CHI',
      'CHI',
    ),

    tntbox: new Token(
      ChainId.BSC_TESTNET,
      '0x1394C83835374590F4d6688D9E33135A635FC774',
      0,
      'LuckyBox',
      'LuckyBox',
    ),
}

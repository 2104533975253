import { createAction } from '@reduxjs/toolkit'
import { NftsProps, UserItems } from "./type"

export const fetchLoading = createAction<{isLoading:boolean}>('mintnft/fetchStatus')

// for mint nft
export const getNftInfo = createAction<NftsProps>('mintnft/getBalanceNft')
export const getDataUser = createAction<UserItems>('mintnft/getDataUser')
export const getRefcode = createAction<{refCode: string}>('mintnft/getRefcode')


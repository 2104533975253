import { createReducer } from '@reduxjs/toolkit'
import {
    fetchLoading, getBalanceDebris, getBalanceNft, getBalanceNftUser, getBalanceTnt, getDataUser, getDataUserTNT, getIsApproveForAllTnt, getNftInfoMint, getNftTypeMaxSupply, getNftTypeSupply, getRefcode, getSupplyNftByType,
} from "./actions"
import { BalanceDebris, DataRef, DataUser, NftBalance, NftByType, NftType, NftTypeMaxSupply, NftTypeSupply, TntBalance, TntWithApproveForAll } from './type'

interface global {
    isLoading:boolean
    listNftMaxSupply: NftTypeMaxSupply[],
    listNftSupply: NftTypeSupply[],
    listDataUser: DataUser[],
    listNft:NftType[],
    listRef: DataRef[]
    lisNftBalance:NftBalance[],
    nftInfo:NftByType[],
    listTntBalance:TntBalance[],
    listDataUserTnt: DataUser[],
    lisBalanceDebris?:BalanceDebris[],
    lisTntWithApprove:TntWithApproveForAll[],
    listNftByType:NftType[]
}

export const initialState: global = {
    isLoading:false,
    listNftMaxSupply:[],
    listNftSupply: [],
    listDataUser: [],
    listNft:[],
    listRef: [],
    lisNftBalance: [],
    nftInfo:[],
    listTntBalance: [],
    listDataUserTnt: [],
    lisBalanceDebris:[],
    lisTntWithApprove: [],
    listNftByType:[]
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(getNftInfoMint, (state, action) => {
            state.listNft = action.payload.listNft
        })
        .addCase(getNftTypeMaxSupply, (state, action) => {
            state.listNftMaxSupply = action.payload.listNftMaxSupply
        })
        .addCase(getBalanceNft, (state, action) => {
            state.lisNftBalance = action.payload.lisNftBalance
        })
        .addCase(getNftTypeSupply, (state, action) => {
            state.listNftSupply = action.payload.listNftSupply
        })
        .addCase(getDataUser, (state, action) => {
            state.listDataUser = action.payload.listDataUser
        })
        .addCase(getRefcode, (state, action) => {
            state.listRef = action.payload.listRef
        })
        .addCase(getBalanceTnt, (state, action) => {
            state.listTntBalance = action.payload.lisTntBalance
        })
        .addCase(getBalanceNftUser, (state, action) => {
            state.nftInfo = action.payload.nftInfo
        })
        .addCase(getDataUserTNT, (state, action) => {
            state.listDataUserTnt = action.payload.listDataUser
        })
        .addCase(getBalanceDebris, (state, action) => {
            state.lisBalanceDebris = action.payload.lisBalanceDebris
        })
        .addCase(getIsApproveForAllTnt, (state, action) => {
            state.lisTntWithApprove = action.payload.lisTntWithApprove
        })
        .addCase(getSupplyNftByType, (state, action) => {
            state.listNftByType = action.payload.listNftByType
        })
        
)
import styled from "styled-components";
import { Flex } from "../Box";

const StyledBottomNav = styled(Flex)`
  position: fixed;
  bottom: 0px;
  width: 100%;
  padding: 25px 8px;
  background: rgba(0, 0, 0, 0.2);
  background-blend-mode: overlay;
  backdrop-filter: blur(40px);
  border-radius: 30px 30px 0px 0px;
  padding-bottom: env(safe-area-inset-bottom);
  html[data-useragent*="TokenPocket_iOS"] & {
    padding-bottom: 45px;
  }
  z-index: 20;
`;

export default StyledBottomNav;

import styled from 'styled-components'
import { Box, Flex } from '@pancakeswap/uikit'

const Card = styled(Box)<{width?: string,padding?: string,border?: string,borderRadius?: string}>`
  width: ${({ width }) => width ?? '100%'};
  padding: ${({ padding }) => padding ?? '1.25rem'};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius ?? '16px'};
  background-color: ${({ theme }) => theme.colors.background};
`
export default Card

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
`
export const CsLightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

export const LightGreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.background};
`

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.colors.dropdown};
`
export const CsCard = styled(Card)`
    background: linear-gradient(0deg, #120803, #120803),
    radial-gradient(128.48% 241.47% at 33.75% -11.12%, #948F8F 0%, rgba(148, 143, 143, 0) 100%);
    overflow:hidden;
`
export const PCard = styled(Flex)`
    width: 100%;
    height: auto;
    min-height: 400px;
    border-radius: 12px;
    background: #170700;
    overflow: hidden;
    flex-direction:column;
    @media screen and (max-width:769px) {
        width: 400px;
    }
`
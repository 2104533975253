import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 28 28" fill="none" {...props}>
        <path d="M17.5004 24.1148C17.2787 24.1148 17.0571 24.0331 16.8821 23.8581L9.2754 16.2514C8.03873 15.0148 8.03873 12.9848 9.2754 11.7481L16.8821 4.14145C17.2204 3.80311 17.7804 3.80311 18.1187 4.14145C18.4571 4.47978 18.4571 5.03978 18.1187 5.37811L10.5121 12.9848C9.95207 13.5448 9.95207 14.4548 10.5121 15.0148L18.1187 22.6214C18.4571 22.9598 18.4571 23.5198 18.1187 23.8581C17.9437 24.0214 17.7221 24.1148 17.5004 24.1148Z" fill="#F5A83B"/>
        <path d="M10.1525 24.2271C9.93083 24.2271 9.70917 24.1454 9.53417 23.9704L1.9275 16.3637C0.690833 15.1271 0.690833 13.0971 1.9275 11.8604L9.53417 4.25375C9.8725 3.91542 10.4325 3.91542 10.7708 4.25375C11.1092 4.59208 11.1092 5.15208 10.7708 5.49042L3.16417 13.0971C2.60417 13.6571 2.60417 14.5671 3.16417 15.1271L10.7708 22.7337C11.1092 23.0721 11.1092 23.6321 10.7708 23.9704C10.5958 24.1337 10.3742 24.2271 10.1525 24.2271Z" fill="#F5A83B"/>
    </Svg>
  );
};

export default Icon;

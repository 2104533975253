import { createReducer } from '@reduxjs/toolkit'
import {
    getDataNft, getDataById, getMetaData, getLoading
} from "./actions"
import { DataNftById, MetaData, PublicDataNft } from './type'


interface global {
    dataNft: PublicDataNft,
    dataById: DataNftById,
    metaData: MetaData,
    isLoading: boolean
}
export const initialState: global = {
    dataNft: null,
    dataById: null,
    metaData: null,
    isLoading: false
    
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(getDataNft, (state, action) => {
            state.dataNft = action.payload.dataNft
        })
        .addCase(getDataById, (state, action) => {
            state.dataById = action.payload.dataById
        })
        .addCase(getMetaData, (state, action) => {
            state.metaData = action.payload.metaData
        })
        .addCase(getLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
)
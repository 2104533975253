import type { ThemeConfig } from "antd";

const theme:ThemeConfig = {
    token: {
        colorPrimary: "#200b02",
        colorBgBase: "white",
        colorIcon: "white",
        colorIconHover: "white"
    },
    components: {
        Input: {
            colorBgContainer: "#200b02",
            colorBorder: "white",
            colorText: "white",
            colorTextPlaceholder: "#bfbfbf",
            colorIcon: "white",
            colorIconHover: "white",
            colorPrimary: "white"
        },
    },
}

export default theme
import { createReducer } from '@reduxjs/toolkit'
import {
    getListNftToTransfer
} from "./actions"


interface global {
    listNftToTransfer:number[]
}
export const initialState: global = {
    listNftToTransfer:[]
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(getListNftToTransfer, (state, action) => {
            state.listNftToTransfer = action.payload.listNftToTransfer
        })
)
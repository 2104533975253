import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 46 46" fill="none" {...props}>
      <circle cx="23" cy="23" r="23" fill="#F7F7F7"/>
      <g filter="url(#filter0_d_114_107543)">
      <path d="M30 15.8945L16 30.1045" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 15.8945L30 30.1045" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
      <filter id="filter0_d_114_107543" x="11.25" y="15.1445" width="23.5" height="23.71" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
      <feFlood floodOpacity="0" result="BackgroundImageFix"/>
      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="2"/>
      <feComposite in2="hardAlpha" operator="out"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0.191667 0 0 0 0 0.186076 0 0 0 0 0.186076 0 0 0 0.25 0"/>
      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_114_107543"/>
      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_114_107543" result="shape"/>
      </filter>
      </defs>
    </Svg>
  );
};

export default Icon;

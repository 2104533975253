import { createAction } from '@reduxjs/toolkit'
import { DataHistory, ListChar, ListNftInfo, ListPenddingRewars, Percent, TokenPrice, TypeActiveTab } from "./type"

export const getActiveTab = createAction<TypeActiveTab>('nftpool/getActiveTab')
export const getPowerToStake = createAction<{powerToStake:string}>('nftpool/getPowerToStake')
export const getPowerToUsStake = createAction<{powerToUnstake:string}>('nftpool/getPowerToUsStake')
export const getSelectedTypeForStake = createAction<{selectedTypeForStake:string}>('nftpool/getSelectedTypeNft')
export const getSelectedTypeNftForUnstake = createAction<{selectedTypeForUnstake:string}>('nftpool/getSelectedTypeNftForUnstake')
export const getNftIdsToStake = createAction<{nftIdsToStake:number[]}>('nftpool/getNftIdsToStake')
export const getNftIdsToUnStake = createAction<{nftIdsToUnStake:number[]}>('nftpool/getNftIdsToUnStake')


// for pool
export const getFetchingStatus = createAction<{isLoading:boolean}>('nftpool/getFetchingStatus')
export const getPoolInfo = createAction<ListPenddingRewars>('nftpool/getPoolInfo')
export const getNftInfo = createAction<ListNftInfo>('nftpool/getNftInfo')
export const getChartData = createAction<ListChar>('nftpool/getChartData')
export const getDataHistory = createAction<DataHistory>('nftpool/getDataHistory')
export const getPageActive = createAction<{pagenumbermkl:number}>('nftpool/getPageActive')
export const getGoldPrice = createAction<TokenPrice>('nftpool/getGoldPrice')
import { scales, variants } from "./types";

export const scaleVariants = {
  [scales.MD]: {
    height: "52px",
    padding: "0 24px",
  },
  [scales.SM]: {
    height: "32px",
    padding: "0 16px",
  },
  [scales.XS]: {
    height: "20px",
    fontSize: "12px",
    padding: "0 8px",
  },
};

export const styleVariants = {
  [variants.PRIMARY]: {
    background: 'linear-gradient(95.63deg, #F0A534 3.54%, #FCC574 42.8%, #EF9515 101.43%)',
    color: "primary",
  },
  [variants.SECONDARY]: {
    background: "transparent",
    border: "2px solid",
    borderColor: "cardBorder", 
    boxShadow: "none",
    color: "text",
    ":disabled": {
      background: "transparent",
    },
    ":hover": {
      background: "primaryBright",
      color:"white",
    },
  },
  [variants.TERTIARY]: {
    background: "tertiary",
    color: "primary",
  },
  [variants.SUBTLE]: {
    background: 'linear-gradient(90deg, #BDBDBD 0%, #EFEFEF 51.13%, #BDBDBD 101.21%)',
    color: "primary",
  },
  [variants.DANGER]: {
    background: "linear-gradient(95.63deg, #FC4E4E 3.54%, #F87979 42.8%, #FC4E4E 101.43%)",
    color: "white",
  },
  [variants.SUCCESS]: {
    background: "success",
    color: "white",
  },
  [variants.TEXT]: {
    background: "transparent",
    color: "primary",
  },
  [variants.LIGHT]: {
    background: "transparent",
    color: "primary",
    boxShadow: "none",
    border: "1px solid",
    borderColor: "primary", 
  },
};

import { createReducer } from '@reduxjs/toolkit'
import {
    fetchLoading,
    getNftInfo,
    getDataUser,
    getRefcode
} from "./actions"
import { NftType, UserItem } from './type'

interface global {
    isLoading:boolean
    listNft:NftType[],
    listDataUser: UserItem[],
    refCode: string
}
export const initialState: global = {
    isLoading:false,
    listNft:[],
    listDataUser: [],
    refCode: ''
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(fetchLoading, (state, action) => {
            state.isLoading = action.payload.isLoading
        })
        .addCase(getNftInfo, (state, action) => {
            state.listNft = action.payload.listNft
        })
        .addCase(getDataUser, (state, action) => {
            state.listDataUser = action.payload.listDataUser
        })
        .addCase(getRefcode, (state, action) => {
            state.refCode = action.payload.refCode
        })
)
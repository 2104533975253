import React from "react";
import styled from "styled-components";
import Flex from "../../components/Box/Flex";
import { MotionBox } from "../../components/Box";
import { ArrowBackIcon, CloseIcon } from "../../components/Svg";
import { IconButton, IconButtonV2 } from "../../components/Button";
import { ModalProps } from "./types";

export const ModalHeader = styled.div`
    align-items: center;
    display: flex;
    padding: 24px 24px 0px 24px;
    position: absolute;
    top:0px;
    left: 0px;
    width: 100%;
  /* position: relative; */
`;

export const ModalTitle = styled(Flex)`
  align-items: center;
  flex: 1;
  justify-content: center;
  color: #23262f;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  gap: 10px;
  padding-top:5px;
`;

export const ModalBody = styled(Flex)`
    width: 100%;
    flex-direction: column;
    max-height: 80vh;
    overflow-y:auto;
    padding:0px!important;
    &::-webkit-scrollbar-thumb {
      display: none;
    } 
    @media screen and (max-width:600px) {
        max-height: 90vh;
    }
`;
export const ModalBodyV2 = styled(Flex)`
  flex-direction: column;
  max-height: 100vh;
  overflow-y:auto;
  padding:0px!important;
  &::-webkit-scrollbar-thumb {
    display: none;
  }
`;

export const WrapIconButton = styled(IconButton)`
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 50%;
  height: 36px;
  width: 36px;
`;

export const ModalCloseButton: React.FC<{ onDismiss: ModalProps["onDismiss"] }> = ({ onDismiss }) => {
  return (
    <WrapIconButton variant="text" onClick={onDismiss} aria-label="Close the dialog" style={{zIndex: '1000'}}>
      <CloseIcon width="36px" height="36px" color="white" />
    </WrapIconButton>
  );
};

export const ModalBackButton: React.FC<{ onBack: ModalProps["onBack"] }> = ({ onBack }) => {
  return (
    <IconButton variant="text" onClick={onBack} area-label="go back" mr="8px">
      <ArrowBackIcon color="white" />
    </IconButton>
  );
};

export const ModalContainer = styled(MotionBox)<{ minWidth: string }>`
  position: relative;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  border-radius: 32px;
  width: 100%;
  max-height: 100vh;
  z-index: ${({ theme }) => theme.zIndices.modal};
  ${({ theme }) => theme.mediaQueries.xs} {
    width: auto;
    min-width: ${({ minWidth }) => minWidth};
    max-width: 100%;
  }
  padding:0px;
  @media screen and (max-width: 500px) {
    padding: 0px;
  }
`;
export const ModalCloseButtonUnbox: React.FC<{ onDismiss: ModalProps["onDismiss"] }> = ({ onDismiss }) => {
  return (
    <Cusbutton variant="text" onClick={onDismiss} aria-label="Close the dialog">
      <CloseIcon width="42px" />
    </Cusbutton>
  );
};

const Cusbutton = styled(IconButtonV2)`
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 50%;
    height: 52px;
    width: 52px;
    z-index: 99;
`

import { Button, Modal, Text, Grid, Box, Message, MessageText, ModalTitle, Heading, Flex } from '@pancakeswap/uikit'
import { ChainId } from '@pancakeswap/sdk'
import Image from 'next/image'
import styled from 'styled-components'
import { useSwitchNetwork, useSwitchNetworkLocal } from 'hooks/useSwitchNetwork'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { chains } from 'utils/wagmi'
import { useTranslation } from '@pancakeswap/localization'
import { useMemo } from 'react'
import { useHistory } from 'contexts/HistoryContext'
import NextLink from 'next/link'
import { useMenuItems } from 'components/Menu/hooks/useMenuItems'
import { getActiveMenuItem, getActiveSubMenuItem, getParentMenu } from 'components/Menu/utils'
import { useRouter } from 'next/router'
import useAuth from 'hooks/useAuth'

export function PageNetworkSupportModal() {
  const { t } = useTranslation()
  const { switchNetworkAsync, isLoading, canSwitch } = useSwitchNetwork()
  const switchNetworkLocal = useSwitchNetworkLocal()
  const { chainId, isConnected, isWrongNetwork } = useActiveWeb3React()
  const { logout } = useAuth()

  const foundChain = useMemo(() => chains.find((c) => c.id === chainId), [chainId])
  const historyManager = useHistory()

  const lastValidPath = historyManager?.history?.find((h) => ['/swap', '/liquidity'].includes(h))

  const menuItems = useMenuItems()
  const { pathname, push } = useRouter()

  const { title, image } = useMemo(() => {
    const url = getParentMenu(pathname)
    const activeMenuItem = getActiveMenuItem({ menuConfig: menuItems, url })
    const activeSubMenuItem = getActiveSubMenuItem({ menuItem: activeMenuItem, url })

    return {
      title: activeSubMenuItem?.disabled ? activeSubMenuItem?.label : activeMenuItem?.label,
      image: activeSubMenuItem?.image || activeMenuItem?.image,
    }
  }, [menuItems, pathname])
  function handleClick(){
    logout().then(() => {
      push('/')
      switchNetworkLocal(ChainId.ONUS)
    })
  }
  return (
    <CsModal title={title || t('Kiểm tran network của bạn')} onDismiss={handleClick} headerBackground="gradientCardHeader">
      <Grid padding="0px 24px 24px 24px" style={{ gap: '16px' }} width={["336px",,"450px"]}>
        <Flex width="100%" height="auto" justifyContent="center">
            <Image
              layout="fixed"
              width="221px"
              height="221px"
              src="/images/hanagold/switch_network.png?version=1.0"
              alt="check your network"
            />
        </Flex>
        <Text bold color='primaryDark'>{t('Tính năng chỉ dành cho Onus chain')}</Text>

        {image && (
          <Box mx="auto" my="8px" position="relative" width="100%" minHeight="250px">
            <Image src={image} alt="feature" unoptimized />
          </Box>
        )}
        {canSwitch ? (
          <Button
            variant={foundChain && lastValidPath ? 'secondary' : 'primary'}
            isLoading={isLoading}
            onClick={() => (isWrongNetwork ? switchNetworkLocal(ChainId.ONUS) : switchNetworkAsync(ChainId.ONUS))}
          >
            {t('Chuyển sang %chain%', { chain: 'Onus chain' })}
          </Button>
        ) : (
          <Message variant="danger">
            <MessageText>{t('Không thể chuyển đổi network. Hãy thử nó trên ví của bạn')}</MessageText>
          </Message>
        )}
        {isConnected && (
          <Button
            variant="light"
            onClick={() =>
              logout().then(() => {
                push('/')
              })
            }
          >
            {t('Ngắt kết nối')}
          </Button>
        )}
        {foundChain && lastValidPath && (
          <NextLink href={lastValidPath} passHref>
            <Button as="a">{t('Ở lại %chain%', { chain: foundChain.name })}</Button>
          </NextLink>
        )}
      </Grid>
    </CsModal>
  )
}

const CsModal = styled(Modal)`
    ${ModalTitle}{
      @media screen and (max-width: 500px) {
        padding-top:30px;
      }
    }
    ${Heading}{
      font-weight:800;
      font-size:22px;
    }
    overflow-y:hidden;
`
import { createReducer } from '@reduxjs/toolkit'
import {
    getActiveTab,
    getFilterByType,
    getSearchId,
} from "./actions"


interface global {
    searchId:string,
    activeIndex:number,
    typeActive:string,
}
export const initialState: global = {
    searchId:"",
    activeIndex:0,
    typeActive:"all",
}
export default createReducer(initialState, (builder) =>
    builder
        .addCase(getSearchId, (state, action) => {
            state.searchId = action.payload.searchId
        })
        .addCase(getActiveTab, (state, action) => {
            state.activeIndex = action.payload.activeIndex
        })
        .addCase(getFilterByType, (state, action) => {
            state.typeActive = action.payload.typeActive
        })
)
import { createAction } from '@reduxjs/toolkit'
import { TypeActiveTab, TypeSearch } from "./type"

export const fetchLoading = createAction<{isLoading:boolean}>('mynft/fetchStatus')

export const getSearchId = createAction<TypeSearch>('mynft/getSearchId')
export const getActiveTab = createAction<TypeActiveTab>('mynft/getActiveTab')
export const getFilterByType = createAction<{typeActive:string}>('mynft/getFilterByType')


// for panigate 
export const getPageNumberCount = createAction<{pagenumbercount:number}>('mynft/getPageNumberCount')

